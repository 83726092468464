<template>
  <div class="photo-box">
    <div class="photo-contect">
      <p class="photo-title">Photos</p>
      <div class="select-pic" v-if="selectPic">
        <div class="image-block">
           <!-- <div class="img-url">
            <img :src="selectPic.url" /> 
          </div> -->
            <el-image
                class="img-url"
                :src="selectPic.url"
                fit="cover"
                :modal-append-to-body="true"
                :preview-src-list="getPrivewImages(this.index)"
              >
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
          <div class="text-block" v-if="is_admin">
            <el-input
              type="textarea"
              resize="none"
              class="img-input"
              :rows="3"
              maxlength="130"
              placeholder="Caption text"
              v-model="selectPic.caption"
            ></el-input>
            <el-input
              type="textarea"
              resize="none"
              class="img-input"
              :rows="4"
              maxlength="130"
              placeholder="Alt text"
              v-model="selectPic.alt_text"
            ></el-input>
          </div>
        </div>

        <div class="del-text" @click="removePic(selectPic.url)">
          Delete Photo
        </div>
      </div>
      <div class="sort-link" type="button" @click="sortDialog = true" v-if="sortData.length > 0">Sort Photos</div>
      <div class="img-list">
        <div
          v-for="(item, index) in data"
          :key="index"
          :class="`img-data ${
            selectPic && item.url == selectPic.url ? 'selected' : ''
          }`"
          @click="selectPhoto(index)"
        >
          <img class="img-url" :src="item.url" />
        </div>
      </div>
      <div class="upload-box" v-loading="loading">
        <el-upload
          multiple
          drag
          :http-request="uploadImages"
          action="imagess"
          list-type="picture-card"
          :show-file-list="false"
        >
          <div class="upload-row">
            <!-- <img src="../../assets/icon/icon-upload.svg" alt="" /> -->
            <span class="upload-text">Add Photo</span>
          </div>
        </el-upload>
      </div>
      
      <!-- <p style="text-align: left" v-if="this.data.length != 0">
        <el-button class="primary-button" @click="$refs.delphoto.show = true"
          >Delete all Images</el-button
        >
      </p> -->
    </div>
    <div class="pb_btn">
      <el-button
        class="primary-button"
        @click="save"
        v-loading.fullscreen.lock="fullscreenLoading"
        ><p class="row btn-row">
          <span>Confirm</span><i class="el-icon-right"></i>
        </p>
      </el-button>
    </div>
    
    <el-dialog
        :visible.sync="sortDialog"
        class="sort-photo-modal"
        center
        @close="closeDialog"
      >
      <div class="img-show">
        <div>
          <draggable
            v-model="sortData"
            @start="drag = true"
            @end="drag = true"
            class="pb_exchange"
          >
            <div
              v-for="(item, index) in sortData"
              :key="index"
              class="img-data-all"
            >
              <div class="img-sort row">
                <div class="img">
                  <!-- <i class="el-icon-delete pc" @click="removePic(index)"></i>
                  <i class="el-icon-close move" @click="removePic(index)"></i> -->
                  <div class="demo-image__preview img-url">
                    <img :src="item.url" class="img-url">
                  </div>
                </div>
              </div>
            </div>
          </draggable>
        </div>
      </div>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeDialog()">Cancel</el-button>
        <el-button type="primary" @click="save()">
          Save
        </el-button>
      </span>
    </template>
      </el-dialog>
    <DeLDialog @del="delAllPhotos" :title="allTitle" ref="delphoto"></DeLDialog>
    <DeLDialog @del="delImg" :title="title" ref="delInfor"></DeLDialog>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import { apiGetProfile } from "../../API/api";
import DeLDialog from "../common/DelDialog";
import { apiGetListing, apiUpdateListing, apiUploadImage } from "../../API/api";
export default {
  components: { draggable, DeLDialog },
  data() {
    return {
      loading: false,
      fullscreenLoading: false,
      data: [],
      sortData: [],
      selectPic: null, // 选中的图片
      index: 0,
      title: "Are you sure you want to delete this photo?",
      allTitle:
        "Do you really want to delete these images? this process cannot be undone.",
      sortDialog:false,
      is_admin:false,
    };
  },
  computed: {
    imgs() {
      let arr = [];
      this.data.forEach((item, index) => {
        arr.push(item.url);
      });
      return arr;
    },
  },
  methods: {
    delAllPhotos() {
      apiUpdateListing(this.$route.params.id, { images: [] })
        .then((res) => {
          this.$store.commit("setListing", JSON.parse(JSON.stringify(res)));
          this.$store.commit("setPropertyPhoto", true);
          this.data = [...res.images];
          this.sortData = [...res.images];
          this.selectPic = null;
          this.$message.success("Success");
          this.$refs.delphoto.show = false;
        })
        .catch((error) => {
          this.$message.error("Save failed");
        })
        .finally(() => {
          this.fullscreenLoading = false;
        });
    },
    // 大图展示
    getPrivewImages(index) {
      let tempImgList = [...this.imgs]; //所有图片地址
      if (index == 0) return tempImgList;
      // 调整图片顺序，把当前图片放在第一位
      let start = tempImgList.splice(index);
      let remain = tempImgList.splice(0, index);
      return start.concat(remain); //将当前图片调整成点击缩略图的那张图片
    },
    //获取房屋详情
    getListing() {
      apiGetListing(this.$route.params.id).then((res) => {
        this.$store.commit("setListing", JSON.parse(JSON.stringify(res)));
        this.$store.commit("setPropertyInfor", true);
        this.$store.commit("setPropertyReuirement", true);
        this.$store.commit("setPropertyPaymentInformation", true);
        if (res.availabilities.length != 0 || res.rates.length != 0) {
          this.$store.commit("setPropertyCalendar", true);
        }
        if (res.images.length != 0) {
          this.$store.commit("setPropertyPhoto", true);
        }
        this.data = [...res.images];
        this.sortData = [...res.images];
        if (this.data.length > 0) this.selectPic = this.data[0];
      });
    },
    // 编辑图片
    editPic(item) {
      this.selectPic = item;
    },
    // 删除相片
    removePic() {
      this.$refs.delInfor.show = true;
    },
    delImg() {
      this.data.splice(this.index, 1);
      this.sortData.splice(this.index, 1);
      this.save();
    },
    // 将图片上传到亚马逊
    uploadImages(param) {
      let form = new FormData();
      form.append("file", param.file);
      this.loading = true;
      apiUploadImage(form)
        .then((res) => {
          if(this.data.length == 0){
            this.selectPic = {
              object: res.object_uuid,
              order: -1,
              alt_text: "",
              caption: "",
              url: res.url,
            }
          }
          this.data.push({
            object: res.object_uuid,
            order: -1,
            alt_text: "",
            caption: "",
            url: res.url,
          });
          this.sortData = [...this.data];
        })
        .catch((err) => {
          this.$message("Upload failure");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 上传图片
    save() {
      // if (this.data.length === 0) return;
      this.data = this.sortData.map((pic, index) => {
        // 排序
        pic.order = ++index;
        return pic;
      });
      this.fullscreenLoading = true;
      apiUpdateListing(this.$route.params.id, { images: this.data })
        .then((res) => {
          this.$store.commit("setListing", JSON.parse(JSON.stringify(res)));
          this.$store.commit("setPropertyPhoto", true);
          this.data = [...res.images];
          this.sortData = [...res.images];
          if (this.data.length > 0) {
            this.selectPic = this.data[0];
          }else{
            this.selectPic = null;
          }
          this.$message.success("Success");
          this.$refs.delInfor.show = false;
          this.sortDialog = false;
        })
        .catch((error) => {
          this.$message.error("Save failed");
        })
        .finally(() => {
          this.fullscreenLoading = false;
        });
    },
    // 上一步
    preStep() {
      this.$router.push({
        name: "property-calendar",
        params: { id: this.$route.params.id },
      });
    },
    // 下一步
    nextStep() {
      this.$router.push({
        name: "property-requirement",
        params: { id: this.$route.params.id },
      });
    },
    selectPhoto(index) {
      this.selectPic = this.data[index];
      this.index = index;
    },
    closeDialog(){
      this.sortData = [...this.data];
      this.sortDialog = false;
    }
  },
  created() {
    if (this.$route.params.id) {
      this.getListing();
      apiGetProfile().then((res) => {
        if(res.is_admin){
          this.is_admin = res.is_admin;
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../css/button.scss";
.photo-box {
  .photo-contect {
    position: relative;
    padding: 20px;
    min-height: 600px;
  }
  .photo-title {
    font-size: 18px;
    color: #36425d;
    font-family: "Roboto-Bold", sans-serif;
    text-align: left;
  }
  // 图片排序
  .img-show {
    .pb_exchange{
      display:flex;
      flex-wrap: wrap;
    }
    .img-data-all {
      margin-bottom: 20px;
      transform: none !important;
      .img-sort {
        align-items: flex-start;
        /deep/ .el-textarea__inner {
          font-family: "Roboto-Regular", sans-serif;
        }
        .img {
          position: relative;
          width: 180px;
          height: 115px;
          margin-right: 20px;
          border-radius: 6px;
          .el-icon-delete {
            position: absolute;
            bottom: 0px;
            left: 0;
            z-index: 1;
            margin-right: 20px;
            width: 100%;
            color: #678993;
            font-size: 20px;
            line-height: 40px;
            display: none;
            background: linear-gradient(
              to bottom,
              rgba(228, 219, 221, 0.2) 0%,
              rgba(250, 248, 249, 0.911) 60%
            );
            opacity: 0.9;
            height: 40px;
          }
          .img-url {
            width: 100%;
            height: 100%;
          }
          &:hover {
            .el-icon-delete {
              display: block;
            }
          }
        }
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  .upload-box {
    width: 160px;
    height: 100px;
    margin-bottom: 20px;
    div {
      width: 100%;
      height: 100%;
    }
    /deep/ .el-upload--picture-card {
      width: 100%;
      height: 100%;
      text-align: center;
      background-color: #f4f9fc;
      padding: 1px;
      border: 2px solid #00b8ff;

      .el-upload-dragger {
        height: 100%;
        width: 100%;
        border: none;
        border-radius: 0;
        box-shadow: none;
        .upload-row {
          display: flex;
          align-items: center;
          justify-content: center;
          .upload-text,
          i {
            color: #00b8ff;
          }
          .upload-text {
            margin-left: 7px;
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
    }
  }
  .pb_top {
    display: flex;
    justify-content: flex-start;
    // display: -webkit-flex;
    // -webkit-flex-wrap: wrap;
    // flex-wrap: wrap;
    /deep/ .el-image .el-icon-picture-outline {
      font-size: 60px;
      line-height: 440px;
      opacity: 0.89;
      display: flex;
      justify-content: center;
    }
    /deep/ .el-image,
    /deep/ .el-image .image-slot {
      width: 520px;
      height: 440px;
      border-radius: 10px;
    }
    /deep/ .el-image .image-slot {
      background-color: #f7f7f7;
    }
    .pb_description {
      width: 550px;
      margin-left: 20px;
      .pb_desctitle {
        margin-top: 0;
        text-align: left;
        font-size: 20px;
        font-weight: 560;
        color: #333;
      }
      .el-button {
        width: 360px;
        height: 60px;
        background: #e5e5e5 0% 0% no-repeat padding-box;
        margin-top: 30px;
        color: #888;
        font-size: 20px;
      }
    }
  }
  // 按钮
  .pb_btn {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    height: 80px;
    align-items: center;
    padding: 0 20px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      border: 1px solid #e1e9ef;
    }
    .el-button {
      .btn-row {
        width: 100px;
        margin: 0;
        justify-content: space-between;
        i {
          font-family: "Roboto-Bold", sans-serif;
          font-size: 20px;
        }
      }
    }
  }
  // 弹出框
  /deep/ .el-dialog {
    .el-dialog__headerbtn .el-dialog__close {
      color: #678993;
      font-weight: 600;
      font-size: 20px;
    }
  }
  .move {
    display: none;
  }
  @media (max-width: 992px) {
    .move {
      display: block;
    }
    .pc {
      display: none;
    }
    .upload-box /deep/ .el-upload--picture-card {
      line-height: 46px;
      height: 46px;
    }
  }
}
.select-pic {
  border-bottom: 1px solid #dcdfe6;
  margin-bottom: 20px;
  .image-block {
    display: flex;
    gap: 10px;
    /deep/ .img-url img {
      border-radius: 5px;
      width: 40vh;
      height: 25vh;
    }

    .text-block {
      width: 100%;
      .img-input {
        margin-bottom: 10px;
        width: 100%;
      }
    }
  }

  .del-text {
    text-align: left;
    color: #ef233c;
    font-size: 18px;
    text-decoration: underline;
    margin: 10px 0px;
    cursor: pointer;
  }
}

.sort-link {
  text-align: left;
  color: #00b8ff;
  font-size: 18px;
  text-decoration: underline;
  margin: 10px 0px;
  cursor: pointer;
}

.img-list {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;

  .selected {
    border: 2.2px solid #00b8ff;
    border-radius: 5px;
    box-shadow: rgba(0, 184, 255, 0.3) 0px 2px 4px;
  }
  .img-data {
    cursor: pointer;
    .img-url {
      border-radius: 5px;
      width: 160px;
      height: 100px;
    }
  }
}

@media only screen and (max-width: 610px){
    .select-pic {
      .image-block{
        flex-wrap: wrap;
        /deep/ .img-url img {
          width: 100%;
        }
      }
    }

    .photo-box .img-show .img-data-all .img-sort .img{
      width:160px;
    }
}

@media only screen and (max-width: 250px){
  .img-list .img-data .img-url,
  .photo-box .img-show .img-data-all .img-sort .img{
    width:100%;
  }


}
</style>
